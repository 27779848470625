window.addEventListener('DOMContentLoaded', () => {
    

window.addEventListener('turbolinks:load', () => {

/* Highlight Current Page in Navigation */

const currentPage = window.location.pathname;

const navLinks = document.querySelectorAll(".primary_navigation a").forEach(link => {

    if (link.pathname === currentPage) {
        link.classList.add("active_page");
    }
       
})

/* --- Mobile Navigation Drop Down Menu --- */

const primaryNav = document.querySelector(".primary_navigation");
const navToggle = document.querySelector(".mobile_nav_toggle");


navToggle.addEventListener('click', () => {
    const visibility = primaryNav.getAttribute("data-visible");

    if (visibility === "false") {
        primaryNav.setAttribute("data-visible", true);
        navToggle.setAttribute("aria-expanded", true);
    }
    else {
        primaryNav.setAttribute("data-visible", false);
        navToggle.setAttribute("aria-expanded", false);
    }
})

/* --- Scroll to Top --- */
const scroll_to_top = document.querySelector(".scroll_to_top");

let lastScrollY = window.scrollY;
let scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight;
let minY = 1000;    //always hide button when below this value (could be changed)

scroll_to_top.classList.add("scroll_to_top_hidden");

window.addEventListener("scroll", () => {

    if (window.scrollY === 0 || lastScrollY < window.scrollY || Math.round(window.scrollY) >= scrollMaxY || window.scrollY <= minY ){
        
        scroll_to_top.classList.add("scroll_to_top_hidden");
    }
    else if (lastScrollY > window.scrollY & (window.scrollY > minY) ){
        
        scroll_to_top.classList.remove("scroll_to_top_hidden");
        
    }
    
    lastScrollY = window.scrollY;

});

/* --- Homepage Loading Screen --- */

if (document.readyState === "complete") {
    console.log("Page is already fully loaded.");
    document.querySelector(".loading_screen").style.transition = "0s";
    checkAllImages();

} else {
    window.addEventListener("load", function() {

        // Hide the loading screen
        document.querySelector(".loading_screen").style.top = "100%";
        
        document.body.style.overflow = "auto";
        console.log("Content fully loaded");

    });
}

function checkAllImages() {
    const images = document.querySelectorAll("img");

    let loaded_image = 0;

    images.forEach(image => {
        if (image.complete) {
            console.log(`Image ${image.src} is already loaded.`);
            loaded_image++;

            if (loaded_image == images.length) {
                //Hide the loading screen
                document.querySelector(".loading_screen").style.top = "100%";
                document.body.style.overflow = "auto";
            }

        } else {
            image.addEventListener("load", () => {
                console.log(`Image ${image.src} finished loading.`);
                loaded_image++;

                if (loaded_image == images.length) {
                    //Hide the loading screen
                    document.querySelector(".loading_screen").style.top = "100%";
                    document.body.style.overflow = "auto";
                }
            });
        }
    });


}

});

});
