window.addEventListener('DOMContentLoaded', () => {
    
window.addEventListener('turbolinks:load', () => {

//------------------------
// Image Gallery
//------------------------

//Click to enlarge image
document.querySelectorAll(".image").forEach(image => image.addEventListener("click", () => {

    image_id = image.id;
    image_full_id = image_id + "_fullsize";

    image_full_arr = document.getElementsByClassName("image_full");

    for (let i = 0; i < image_full_arr.length; i++) {
        if (image_full_arr[i].id === image_full_id) {
            image_full = image_full_arr[i];
        }
      } 
  
    image_full.style.opacity = '1';
    image_full.style.height = '100%';

    if ( (window.innerWidth < 1200) || (window.screen.width < 1200) ) {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        console.log(document.body.style.overflow);
    }

}));

//Click again to close image
document.querySelectorAll(".image_full").forEach(image_full => image_full.addEventListener("click", () => {

    
    image_full.style.opacity = '0';
    image_full.style.height = '0px';

    if ( (window.innerWidth < 1200) || (window.screen.width < 1200) ) {
        document.body.style.overflow = 'auto'; // Enable scrolling
    }

}));

//Image Loading
const lowres_image_full = document.querySelectorAll(".image_full, .image, .tile_image").forEach(lowres_image_full => {

    const image = lowres_image_full.querySelector("img");

    function loaded() {
        lowres_image_full.classList.add("loaded");
    }

    if (image.loaded) {
        loaded();
    } else {
        image.addEventListener("load", loaded);
    }
})

//------------------------
// Poem Control
//------------------------

   
document.querySelectorAll("#poem").forEach(elem => elem.addEventListener("click", () => {

    const day_content = elem.parentElement.parentElement.parentElement.parentElement;
    const poem_container = day_content.querySelector(".poem_pop_up_container");

    var transform_end   = "translateY(-100%)";

    poem_container.style.transform = transform_end;
    


}));

document.querySelectorAll(".poem_close").forEach(elem => elem.addEventListener("click", () => {


    const poem_container = elem.parentElement.parentElement;

    var transform_start = "translateY(0%)"

    poem_container.style.transform = transform_start;

}));
    
    
//------------------------
// Slider Control
//------------------------

document.querySelectorAll("#slider").forEach(elem => elem.addEventListener("click", () => {

    const day_content = elem.parentElement.parentElement.parentElement.parentElement;
    const slider_container = day_content.querySelector(".slider_pop_up_container");

    var transform_end   = "translateY(-200%)";

    slider_container.style.transform = transform_end;
    


}));

document.querySelectorAll(".slider_close").forEach(elem => elem.addEventListener("click", () => {


    const slider_container = elem.parentElement.parentElement;

    var transform_start = "translateY(-100%)"

    slider_container.style.transform = transform_start;


}));
    
    
    
//Slider JS
const slider = document.querySelectorAll('.slider').forEach(slider => {
    
    const slider_pop_up_container = slider.parentElement.parentElement;
    const leftArrow = slider_pop_up_container.querySelector('.left');
    const rightArrow = slider_pop_up_container.querySelector('.right');
    const indicatorParent = slider_pop_up_container.querySelector('ul');
    
    var slider_pos = 0;
    var slider_child_count = slider.childElementCount;
    
    rightArrow.addEventListener('click', function() {
        if (slider_pos < ( (slider_child_count-1))) {
            slider_pos = slider_pos + 1;
        }
    
        slider_pop_up_container.querySelector('li.selected').classList.remove('selected');
        indicatorParent.children[slider_pos].classList.add('selected');
    
        slider.style.transform = `translate(${-slider_pos*100}%)`;
    });
    
    leftArrow.addEventListener('click', function() {
        if (slider_pos > 0) {
            slider_pos = slider_pos - 1;
        }
    
        slider_pop_up_container.querySelector('li.selected').classList.remove('selected');
        indicatorParent.children[slider_pos].classList.add('selected');
    
        slider.style.transform = `translate(${-slider_pos*100}%)`;
    });

    slider_pop_up_container.querySelectorAll('li').forEach(function(indicator, ind) {

        indicator.addEventListener('click', function() {
            slider_pos = ind;
            slider_pop_up_container.querySelector('li.selected').classList.remove('selected');
            indicator.classList.add('selected');
            slider.style.transform = `translate(${-ind*100}%)`;
        });
    
    });
});

//------------------------
// Title Page Animations
//------------------------

const title_dividers = document.querySelectorAll('.title_divider');
const title_info_rows = document.querySelectorAll('.title_info_row');
const day_titles = document.querySelectorAll('.title_text_container h1');

const trigger_down = window.innerHeight * 3 / 4;
const trigger_up = window.innerHeight / 2;

window.addEventListener('scroll', function() {

    title_dividers.forEach(divider => {
        var divider_top = divider.getBoundingClientRect().top;
        if (divider_top < trigger_down) {
            divider.classList.add('show');
        }
        else {
            divider.classList.remove('show');
        }
    });

    //title info rows on title page
    title_info_rows.forEach(info_row => {
        var info_container_top = info_row.parentElement.getBoundingClientRect().top;

        if (info_container_top < trigger_down) {
            info_row.classList.add('show');
        }
        else if (info_container_top > trigger_up) {
            info_row.classList.remove('show');
        }
    });

    //main title on title page
    day_titles.forEach(title => {
        
        var title_top = title.parentElement.getBoundingClientRect().top;

        if (title_top < trigger_down) {
            title.classList.add('show');
        }
        else {
            title.classList.remove('show');
        }

    });
    

});

/*

Purpose : Contains all javascript for TRT 2023 section of Travel Page
Created : Sept. 06, 2023
Author  : Brandonius

Functions
1. resizeMap() - Update width of all TRT Maps (class="trt_map")
                to match aspect ratio of source image.

2. zoomMap(zoom, transX, transY) - Zoom in on desired area of TRT map (class="trt_map_image") based 
                                on current day's hiked section. Uses the transform property with
                                'scale(A)' and 'translate(X,Y)' property values.

        zoom   - value passed to 'scale(A)' property value of [transform]
        transX - value passed to first argurment of 'translate(X,Y)' property value of [transform]
        transY - value passed to second argurment of 'translate(X,Y)' property value of [transform]

Event Listeners
1. On-Click : TRT Map Zoom In/Out
2. On Window Resize : resizeMap()

*/

// Zoom Map
function zoomMap(map, zoom, transX, transY) {
    
    
        //Zoom in on desired section
        var map_zoom_factor = zoom;
        var translate_X = transX;
        var translate_Y = transY;
    
        var transform_string = `scale(${map_zoom_factor}) translate(${translate_X}%, ${translate_Y}%)`;
        
        map.style.transform = transform_string;
        
    
        
}

// --- Variables --- //
//Transform Translate X and Y
var zoom_day1  = [-27, 29];
var zoom_day2  = [-27, 29];
var zoom_day3  = [-27, 2 ];
var zoom_day4  = [-27, -11];
var zoom_day5  = [-27, -21];
var zoom_day6  = [-24, -29];
var zoom_day7  = [-13, -29];
var zoom_day8  = [-12, -29];
var zoom_day9  = [-8, -29];
var zoom_day10 = [8, -27];
var zoom_day11 = [15, -19];

const trt_maps = document.querySelectorAll('.trt_map_image');
const map_trigger = window.innerHeight / 4;
const map_zoom_factor = 2.4;

//On click - Zoom Out / Zoom In
trt_maps.forEach(elem => elem.addEventListener("click", () => {

    var translateX = ( elem.id == 'day1'  ) ? zoom_day1[0] :
                        ( elem.id == 'day2'  ) ? zoom_day2[0] :
                        ( elem.id == 'day3'  ) ? zoom_day3[0] :
                        ( elem.id == 'day4'  ) ? zoom_day4[0] :
                        ( elem.id == 'day5'  ) ? zoom_day5[0] :
                        ( elem.id == 'day6'  ) ? zoom_day6[0] :
                        ( elem.id == 'day7'  ) ? zoom_day7[0] :
                        ( elem.id == 'day8'  ) ? zoom_day8[0] :
                        ( elem.id == 'day9'  ) ? zoom_day9[0] :
                        ( elem.id == 'day10' ) ? zoom_day10[0] :
                        ( elem.id == 'day11' ) ? zoom_day11[0] : 0 ;
    
    var translateY = ( elem.id == 'day1'  ) ? zoom_day1[1] :
                        ( elem.id == 'day2'  ) ? zoom_day2[1] :
                        ( elem.id == 'day3'  ) ? zoom_day3[1] :
                        ( elem.id == 'day4'  ) ? zoom_day4[1] :
                        ( elem.id == 'day5'  ) ? zoom_day5[1] :
                        ( elem.id == 'day6'  ) ? zoom_day6[1] :
                        ( elem.id == 'day7'  ) ? zoom_day7[1] :
                        ( elem.id == 'day8'  ) ? zoom_day8[1] :
                        ( elem.id == 'day9'  ) ? zoom_day9[1] :
                        ( elem.id == 'day10' ) ? zoom_day10[1] :
                        ( elem.id == 'day11' ) ? zoom_day11[1] : 0 ;

    var transform_string = `scale(${map_zoom_factor}) translate(${translateX}%, ${translateY}%)`;
    elem.style.transform = (elem.style.transform == transform_string) ? 'scale(1.0)' : transform_string;

    updateTransitions();

}));

//On Scroll - Zoom In
window.addEventListener("scroll", function() {

    //TRT Maps!!!
    trt_maps.forEach(map => {
        
        var map_top = map.parentElement.getBoundingClientRect().top;
    
        var translateXY = (map.id == 'day1' ) ? zoom_day1 :
                            (map.id == 'day2' ) ? zoom_day2 :
                            (map.id == 'day3' ) ? zoom_day3 :
                            (map.id == 'day4' ) ? zoom_day4 :
                            (map.id == 'day5' ) ? zoom_day5 :
                            (map.id == 'day6' ) ? zoom_day6 :
                            (map.id == 'day7' ) ? zoom_day7 :
                            (map.id == 'day8' ) ? zoom_day8 :
                            (map.id == 'day9' ) ? zoom_day9 :
                            (map.id == 'day10') ? zoom_day10 :
                            (map.id == 'day11') ? zoom_day11 : [0,0] ;

        if ( (map_top < map_trigger) ) {
            zoomMap(map, map_zoom_factor, translateXY[0], translateXY[1]);
        }

    });

});



// Resize Map on Window Resize
function resizeMap(zoom, transX, transY) {
document.querySelectorAll(".trt_map").forEach(elem => {
    
    var map_aspect_ratio = 0.526; //Original image: 900x1711
    var map_height = elem.offsetHeight;
    elem.style.width = `${map_aspect_ratio*map_height}px`;

});


}

//On load: Resize Map and Zoom into desired area
resizeMap();

//Update map size when screen dimensions change
window.addEventListener("resize", resizeMap);


//Function to update transition times for map scale/zoom
//Allows first transition to be longer than on-click events
function updateTransitions() {
    document.querySelectorAll(".trt_map_image").forEach(elem => {
    
        elem.style.transition = '2s';
        elem.style.transitionDelay = '0s';
    
    });
    
    
}

});

});
    
    
    
  
    
    











